import { useMutation } from '@apollo/client'
import {
  Core_TransactionType,
  LandingCreateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  AnyInputConfig,
  GridForm,
  InputType,
  TrackedButton,
  TrackedLink,
} from '@flock/shared-ui'
import { createLeadHelper, localStore, UserEventType } from '@flock/utils'
import { Close } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import {
  FLOCK_FEE_COLLATERAL,
  RECENT_FUND_PERFORMANCE_URL,
} from '../../../constants'
import { getEventTokenUUID, track } from '../../../utils/analytics'
import {
  FaqAccordionProps,
  FaqAccordionPresentationalProps,
} from './faqAccordionTypes'

const useFaqAccordion: (
  props: FaqAccordionProps
) => FaqAccordionPresentationalProps = (props: FaqAccordionProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const sectionIds = [
    'Overview',
    'About Flock Homes',
    'Compare & Contrast',
    `Flock's Portfolio`,
    'Transaction Process',
    'Client Experience',
  ]

  const onHowItWorksClick = () => {
    window.open(
      `${window.origin}/how-it-works?utmSource=faq-how-it-works`,
      '_blank'
    )
  }

  const onStepUpBasisClick = () => {
    window.open(
      'https://www.forbes.com/advisor/investing/what-is-step-up-in-basis/',
      '_blank'
    )
  }

  const onAboutTeamClick = () => {
    window.open(`${window.origin}/about?utmSource=faq-about-team`, '_blank')
  }

  const onPortfolioPageClick = () => {
    window.open(`${window.origin}/portfolio?utmSource=faq-portfolio`, '_blank')
  }

  const onSECFilingsClick = () => {
    window.open(
      'https://www.sec.gov/cgi-bin/browse-edgar?company=Flock+Homes&match=&filenum=&State=&Country=&SIC=&myowner=exclude&action=getcompany',
      '_blank'
    )
  }

  const onFeeDocumentClick = () => {
    window.open(FLOCK_FEE_COLLATERAL, '_blank')
  }

  const onSubmitAddressClick = (question: string) => {
    window.open(`${window.origin}/onboarding?utmSource=${question}`, '_blank')
  }

  const ModalWrapper = styled(Paper)({
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '70%',
    maxHeight: '75vh',
    height: '370px',
    overflowY: 'auto',
    padding: '48px',
    borderRadius: '24px',
  })

  const contactInfoInputConfigs: AnyInputConfig[] = [
    {
      name: 'fullName',
      type: InputType.Text,
      required: true,
      props: {
        label: 'Full name',
        placeholder: 'First and last name',
      },
    },
    {
      name: 'email',
      type: InputType.Text,
      required: true,
      props: {
        label: 'Email',
        format: 'email',
        placeholder: 'email@flockhomes.com',
      },
    },
    {
      name: 'phone',
      type: InputType.Text,
      required: true,
      props: {
        label: 'Phone',
        format: 'phone',
        placeholder: '111-111-1111',
      },
    },
  ]

  const [factSheetModalOpen, setFactSheetModalOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [createLead] = useMutation(LandingCreateLeadDocument)

  const onClose = () => {
    setFactSheetModalOpen(false)
  }

  const submitContactInfo = async (result: any) => {
    try {
      setLoading(true)

      const leadData = {
        contactInfo: {
          fullName: result.fullName,
          email: result.email,
          phoneNumber: result.phone,
        },
        answers: JSON.stringify({
          isFlockFactSheetInfo: true,
        }),
        transactionType: Core_TransactionType.TransactionTypeContactInformation,
        eventTokenUuid: getEventTokenUUID(),
      }

      const queryParams = localStore?.getItem('queryParams')
      let parsedQueryParams: any = {}
      if (queryParams) {
        parsedQueryParams = JSON.parse(queryParams)
      }

      track('requestedFactSheet', {
        type: 'button',
        leadConversion: 'finished',
        factSheetCta: true,
        actionType: UserEventType.BUTTON_CLICK,
      })
      await createLeadHelper(leadData, parsedQueryParams, createLead)

      setLoading(false)
      setFactSheetModalOpen(false)
    } catch (e) {
      setLoading(false)
      setFactSheetModalOpen(false)
    }
    window.open(RECENT_FUND_PERFORMANCE_URL, '_blank')
  }

  const faqContent = [
    {
      header: 'Overview',
      content: [
        {
          title: 'What is Flock Homes?',
          text: (
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="p2">
                Founded in 2020, Flock Homes is a real estate company and fund
                operator empowering landlords to exit from their rental
                properties through the 721 exchange.
              </Typography>
              <Typography variant="p2">
                Through Flock Homes, real estate investors can use the 721
                exchange to exchange their single-family, duplex, triplex, or
                quadplex rental properties for ownership in Flock’s fund without
                triggering capital gains taxes. While in Flock’s fund, investors
                benefit from continued access to steady cash flow and
                residential real estate appreciation, without any
                responsibilities of managing rental properties.
              </Typography>
            </Box>
          ),
          idx: 0,
        },
        {
          title: 'Where did the 721 exchange originate?',
          text: (
            <>
              721 exchange refers to Section 721 of the US Internal Revenue
              Code, the same tax code that stipulates the commonly utilized
              Section 1031 (also known as the 1031 exchange). Section 721 was
              established in 1954 and has been used for decades by institutional
              investors to minimize taxes on real estate. Flock Homes has
              pioneered a platform that empowers individual landlords to access
              the same benefits of the 721 exchange. See our{' '}
              <TrackedLink onClick={onHowItWorksClick}>
                How It Works
              </TrackedLink>{' '}
              page for more details.
            </>
          ),
          idx: 1,
        },
        {
          title: 'Once I join Flock, do I still own my home?',
          text: 'No. Once you join Flock, title of your homes is transferred over to the fund and you now own equity in Flock’s diversified fund. As a result, you retain all of the benefits of real estate ownership, including access to cash flow and appreciation potential. However, you do not hold liability for managing any singular home.',
          idx: 2,
        },
        {
          title: 'What kind of homes can I contribute to the Flock fund?',
          text: (
            <>
              Flock’s fund accepts single-family, duplex, triplex, and fourplex
              rental homes. All rental homes that join Flock’s fund must meet
              our financial return requirements. Homes with lower mortgage
              balances (generally, less than 50% of the home’s value) are more
              likely to be accepted into the fund. To see if yours qualifies,{' '}
              <TrackedLink
                onClick={() => onSubmitAddressClick('faq-what-kind-of-homes')}
              >
                click here to submit your rental home
              </TrackedLink>{' '}
              for an evaluation and our team will connect with you to discuss
              your valuation.
            </>
          ),
          idx: 3,
        },
        {
          title: `How do I earn as a client of Flock’s fund?`,
          text: `With your ownership in Flock’s fund, you have the ability to access cash flow from the rental income collected from the fund's portfolio of homes, and you continue benefitting from potential real estate appreciation based on the price performance of the homes in Flock's fund. You also transition into a fully passive approach to real estate investing, which has given Flock clients back countless hours of time and invaluable peace of mind. All operations of the homes in Flock’s fund, including leasing, repairs, and even HOA payments are fully managed by our team of professionals.`,
          idx: 4,
        },
        {
          title: `How does Flock help me minimize my taxes?`,
          text: (
            <>
              The 721 exchange allows you to defer substantial capital gains and
              depreciation recapture taxes you might otherwise trigger in a
              traditional sale of property. Following the minimum hold period,
              you can also access greater cash flow quarterly and better control
              your tax liability by liquidating Flock equity over time. Finally,
              Flock equity also benefits from a step-up in tax basis upon
              inheritance (
              <TrackedLink onClick={onStepUpBasisClick}>
                read this for a detailed explanation on the step-up in basis
              </TrackedLink>
              ), which can provide your heirs significant tax savings when they
              choose to liquidate their equity. We recommend you consult with
              your tax advisor, attorney, and/or the IRS website to understand
              how Flock can complement your personalized tax planning.
            </>
          ),
          idx: 5,
        },
      ],
    },
    {
      header: 'About Flock Homes',
      content: [
        {
          title:
            'What is the relationship between Flock Homes and Flock’s fund?',
          text: 'Flock Homes is the General Partner of Flock’s fund (Flock Homes OP LP). In other words, Flock Homes acts as the manager of the fund, which is collectively owned by all of the investors who have contributed homes to the fund.',
          idx: 6,
        },
        {
          title: 'What experience does the Flock Homes team have?',
          text: (
            <>
              The Flock Homes team encompasses decades of investment and
              management experience from the largest real estate institutions in
              the world, including J.P. Morgan, State Street Global Advisors,
              and Progress Residential, and has used their expertise to help
              100+ landlords retire from real estate through Flock Homes.{' '}
              <TrackedLink onClick={onAboutTeamClick}>
                Learn more about the team here
              </TrackedLink>
              .
            </>
          ),
          idx: 7,
        },
        {
          title: 'What happens if Flock Homes goes bankrupt?',
          text: (
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="p2">
                Flock Homes, our company and General Partner of the Fund, is an
                independent entity separate from the Flock Fund, which holds the
                high-quality, income-generating assets collectively owned by
                clients (or, Limited Partners). This structure ensures that the
                Fund can continue operating as long as a fund administrator is
                in place.
              </Typography>
              <Typography variant="p2">
                In the unlikely event that Flock Homes goes bankrupt, and to
                future-proof client wealth, Flock has entered into an agreement
                with Roofstock, a leading single-family rental (SFR) platform
                that manages 25,000+ homes across 35+ markets, to act as a
                standby servicer to the Fund.
              </Typography>
              <Typography variant="p2">
                While Roofstock is not formally responsible for management today
                and does not have any management authority, Flock’s arrangement
                engages Roofstock to become versed in the Fund’s operations and
                assets, including the Fund’s tax-driven execution and
                administration of 721 Exchanges. In the event there is a need to
                transition management of the Fund from Flock, Roofstock will,
                subject to the requisite approvals, be in a position to
                seamlessly integrate into the role of Fund Manager.
              </Typography>
            </Box>
          ),
          idx: 8,
        },
        {
          title: 'What is Flock’s historical performance?',
          text: (
            <>
              <Modal open={factSheetModalOpen} onClose={onClose}>
                <ModalWrapper>
                  <Box position="fixed" top="16px" right="16px">
                    <IconButton onClick={onClose}>
                      <Close />
                    </IconButton>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Typography variant="h3" color="moneyGreen.main">
                      View Flock&apos;s Fact Sheet
                    </Typography>
                    <GridForm
                      onSubmit={submitContactInfo}
                      inputConfigs={contactInfoInputConfigs}
                      loading={loading}
                      ctaBoxProps={{
                        width: '114px',
                        position: 'fixed',
                        bottom: '24px',
                        right: '24px',
                      }}
                      gridProps={{
                        spacing: 3,
                      }}
                      ctaText="Submit"
                    />
                  </Box>
                </ModalWrapper>
              </Modal>
              <Box>
                Flock has met or exceeded its cash return target to investors
                every quarter since inception.{' '}
                <TrackedButton
                  variant="text"
                  onClick={() => setFactSheetModalOpen(true)}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    lineHeight: '1rem',
                  }}
                  trackingConfig={{
                    name: `faq-fact-sheet-request`,
                    properties: {
                      leadConversion: 'started',
                    },
                  }}
                >
                  You can see our most recent Quarterly Fact Sheet for detailed
                  performance figures here.
                </TrackedButton>
              </Box>
            </>
          ),
          idx: 9,
        },
        {
          title: 'Is Flock regulated by the SEC?',
          text: (
            <>
              Yes, Flock’s fund is regulated by the SEC and we file a Form D
              annually.{' '}
              <TrackedLink onClick={onSECFilingsClick}>
                You can find our filings on the SEC website here
              </TrackedLink>
              .
            </>
          ),
          idx: 10,
        },
      ],
    },
    {
      header: 'Compare & Contrast',
      content: [
        {
          title:
            'What is the difference between the 721 exchange and the 1031 exchange?',
          text: 'Many real estate investors utilize the 1031 exchange to sell their home for proceeds, and use those proceeds, tax-deferred, to purchase other investment real estate. With Flock Homes, investors can use the 721 exchange to seamlessly exchange their homes, tax-deferred, for direct ownership in Flock’s managed real estate fund. With a 1031 exchange, investors commonly continue to be active investors and operators of real estate properties. With Flock, the 721 exchange enables investors to take a long-term, passive approach to real estate investing.',
          idx: 11,
        },
        {
          title: 'What is the advantage over hiring a property manager?',
          text: 'A good property manager can offer some relief from your rentals, but as long as you hold title, you are financially and legally liable. This includes small items like vacancies, repairs, and evictions, but also big items like large renovations and disasters. By having ownership in Flock’s fund, your risk is diversified across hundreds of institutionally managed homes - you’re not liable for any single property. Our team manages everything, including leasing, repairs, and HOA payments, for every home in the fund. Because we are accountable to the long-term performance of the fund’s homes, our incentives are also closely aligned with yours. ',
          idx: 12,
        },
        {
          title:
            'What is the advantage over selling my house and buying shares of a REIT?',
          text: (
            <>
              Depending on your ownership history, you could trigger thousands
              in capital gains and depreciation recapture taxes when you sell
              your rental homes. Instead of buying shares of a REIT and
              investing with significantly reduced after-tax proceeds, Flock
              Homes allows you to earn from a fund with tax-advantaged proceeds.
              The difference in returns can be substantial. Furthermore, Flock’s
              fund is purpose-built for long-term-oriented, retiring investors.
              Our platform also provides unparalleled reporting transparency. If
              you’d like an assessment of your estimated Flock returns,{' '}
              <TrackedLink
                onClick={() => onSubmitAddressClick('faq-sell-and-buy-reit')}
              >
                click here to submit a rental address
              </TrackedLink>
              , and one of Flock’s Single Family Rental Directors can provide
              you with a complimentary evaluation and a demo.
            </>
          ),
          idx: 13,
        },
      ],
    },
    {
      header: `Flock's Portfolio`,
      content: [
        {
          title: 'What kinds of homes are in Flock’s fund?',
          text: (
            <>
              Flock’s fund contains the same types of homes that Flock acquires:
              single-family rental homes, duplexes, triplexes, and fourplexes
              that meet Flock’s financial return requirements. Visit our{' '}
              <TrackedLink onClick={onPortfolioPageClick}>
                portfolio page
              </TrackedLink>{' '}
              to view the homes that are in Flock’s fund.
            </>
          ),
          idx: 14,
        },
        {
          title: 'How does Flock Homes operate the homes in the fund?',
          text: 'Flock Homes operates all homes in the fund as long-term rentals. We have a local team in every market where we own homes, complemented by third-party property managers that we actively manage. We believe local, responsive, and knowledgeable property management delivers the best experience for residents, the community, and ultimately, the fund clients.',
          idx: 15,
        },
        {
          title: 'How does Flock Homes determine the value of the fund?',
          text: 'The fund’s value is refreshed every quarter using a combination of automated valuation models (AVMs) and third-party appraisers. Every home in the fund is required to be appraised by a third party appraiser once every 24 months.',
          idx: 16,
        },
        {
          title:
            'Will Flock Homes ever sell the homes I contributed to the fund?',
          text: 'Flock’s goal is to own and operate homes on a long-term basis as single-family rentals. That being said, our fiduciary duty is to generate the best returns for the fund and its investors, and from time to time, we will strategically reposition individual assets to optimize fund performance. In these situations, Flock Homes has historically utilized 1031 exchanges where possible to preserve tax efficiency for contributors.',
          idx: 17,
        },
      ],
    },
    {
      header: 'Transaction Process',
      content: [
        {
          title: 'How do you determine your valuations?',
          text: 'Flock’s goal is to provide fair-market valuations for all rental properties. We will only transact on homes that meet our return criteria and support the performance of the fund. Our valuations are determined through an industry-standard underwriting process, incorporating leading AVMs and market comparables, based on property characteristics such as configuration, location, and condition.  We perform formal inspections on all prospective homes before finalizing our valuations.',
          idx: 18,
        },
        {
          title: 'Can Flock acquire homes with a tenant?',
          text: 'Yes. Flock Homes can accept homes that are occupied or vacant. If the home is occupied, we will honor the existing lease and work with the resident to renew once the lease expires.',
          idx: 19,
        },
        {
          title: 'Can Flock acquire homes with outstanding mortgages?',
          text: 'Yes. Flock Homes can acquire homes with outstanding mortgages, however, homes with lower mortgage balances (generally, less than 50% of the home’s value) are more likely to surpass Flock’s acquisition criteria for the fund. At close, Flock Homes pays off your existing mortgage with your lender, and the value of Flock equity you receive will be tied to the remaining value of your equity.',
          idx: 20,
        },
        {
          title: 'Can I receive cash?',
          text: (
            <>
              While we are not a cash buyer, we do offer limited cash at close
              on a case-by-case basis.{' '}
              <TrackedLink
                onClick={() => onSubmitAddressClick('faq-can-i-recieve-cash')}
              >
                Click here to submit a rental address for an evaluation
              </TrackedLink>{' '}
              and a Flock Homes Single Family Rental Director will connect with
              you to discuss available options.
            </>
          ),
          idx: 21,
        },
        {
          title: 'What fees does Flock Homes charge?',
          text: (
            <>
              Flock Homes charges investors two simple fees: a one-time
              onboarding fee of 6% and an ongoing annual management fee of 1%.
              The onboarding fee is deducted from the value of your received
              equity in Flock’s fund, and the ongoing management fee is
              collected from the net operating income of the fund. Investors
              typically do not need to separately provide cash to transact or
              own with Flock. Unlike many other funds, Flock does not charge a
              performance fee. Read our{' '}
              <TrackedLink onClick={onFeeDocumentClick}>
                Fee Document
              </TrackedLink>{' '}
              for more information on our fees.
            </>
          ),
          idx: 22,
        },
        {
          title: 'What happens with my rental homes after closing?',
          text: 'The title of each rental home is transferred to Flock’s fund, of which you are now an investor. After closing, we will perform necessary value-add renovations, repairs, or maintenance to maximize long-term earning potential. We continue to lease them out as long-term, single-family rental properties.',
          idx: 23,
        },
      ],
    },
    {
      header: 'Client Experience',
      content: [
        {
          title: 'What returns should I expect from Flock?',
          text: 'Flock’s fund targets an 8-12% internal rate of return to its investors based on the performance of the portfolio of homes in Flock’s fund. This return encompasses 1) income generated by the portfolio’s rental activity and 2) appreciation of your equity based on the price performance of the portfolio’s real estate. For many Flock clients, the peace of mind and countless hours they reclaim is an invaluable benefit.',
          idx: 24,
        },
        {
          title: 'How do I access cash flow?',
          text: 'Cash flow is paid quarterly. You can receive it as cash or equity in the fund. Historically, cash received by investors in Flock has also benefitted from favorable tax advantages.',
          idx: 25,
        },
        {
          title:
            'What access do I have to the portfolio and my financial performance?',
          text: (
            <>
              Upon joining Flock’s fund, you will gain access to your
              personalized online Client Portal, where you will have full
              detailed reporting on your financial performance. You also gain
              full visibility into the fund’s financials and real-time
              asset-level activity, such as individual leasing, renovations, and
              more. To preview the portal,{' '}
              <TrackedLink
                onClick={() =>
                  onSubmitAddressClick('faq-portfolio-and-performance')
                }
              >
                click here to submit your rental address for an evaluation
              </TrackedLink>{' '}
              and one of Flock&apos;s Single Family Rental Directors can provide
              you with a complimentary demo.
            </>
          ),
          idx: 26,
        },
        {
          title: 'What communications will I have with the Flock team?',
          text: 'We strive to provide you with a white-glove and collaborative ownership experience. You’ll have a direct line to Flock’s Client Advisor. Additionally, we hold quarterly investor calls to report on portfolio performance and strategy and quarterly Clients’ Forum meetings discuss our performance with you live.',
          idx: 27,
        },
        {
          title: 'Will I still receive depreciation from my stake in Flock?',
          text: 'Yes. If your contributed property had an unclaimed depreciation balance, you will continue to receive depreciation.',
          idx: 28,
        },
        {
          title: 'How do I liquidate my equity?',
          text: 'After a minimum hold period, your equity can be redeemed for cash directly with Flock, subject to the terms and conditions of the fund documents. Your equity can be redeemed all at once or spread out over a number of years to reduce potential tax liability. Just like selling investment properties, redeeming your equity for cash can be a taxable event.',
          idx: 29,
        },
        {
          title: 'Can I pass my Flock equity onto my heirs?',
          text: 'Yes, with significant tax advantages. Like a traditional rental property, Flock equity ownership benefits from a step-up in tax basis upon inheritance, which can grant your heirs substantial tax savings when they eventually liquidate. Additionally, the flexibility afforded by owning equity in Flock’s fund rather than one illiquid property makes Flock an attractive estate planning instrument. However, we recommend you consult with your tax advisor, attorney, and/or the IRS website for personalized advice on your situation.',
          idx: 30,
        },
      ],
    },
  ]

  return {
    ...props,
    isMobile,
    isTablet,

    faqContent,
    sectionIds,
  }
}

export default useFaqAccordion
