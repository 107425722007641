import React from 'react'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../components/utils'
import FaqHeroSection from '../components/FaqComponents/FaqHeroSection'
import FaqAccordion from '../components/FaqComponents/FaqAccordion/FaqAccordion'

const pageTitle = 'Flock | FAQ'
const pageDescription = 'You have questions? We have answers.'

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const FAQ = () => {
  useRecordPageDuration()
  useScrollDepthTracker()

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="faq"
      headerBackground="trustBlue.main"
    >
      <FaqHeroSection />
      <FaqAccordion />
    </PageWrapper>
  )
}

export default FAQ
